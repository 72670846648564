/* eslint-disable @typescript-eslint/naming-convention */
// @ts-nocheck
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  DeleteTimeSheet,
  GetTimeSheet,
  GetTimeSheets,
  GetWorkOrderTimeSheets,
  SetSelectedTimeSheet,
  UpdateCreateTimeSheets,
  UpdatePostedTimesheetLinesStatus,
} from '@stores-actions/timesheet.action';
import { TimeSheet } from '@stores-models/timesheet';
import { TimeSheetStoreService } from '@stores-services/timesheet-store.service';

import { AuthState } from './authentication.state';

export class TimeSheetStateModel {
  timeSheets: TimeSheet[] = [];
  selectedtimeSheet!: TimeSheet | null;
  initialized!: boolean;
}

/**
 * Time Sheet Lines metadata and action mappings.
 */
@State<TimeSheetStateModel>({
  name: 'timeSheet',
  defaults: {
    timeSheets: [],
    selectedtimeSheet: null,
    initialized: false,
  },
})
@Injectable()
export class TimeSheetState {
  constructor(
    private timeSheetStoreService: TimeSheetStoreService,
    private readonly store: Store,
    private translocoService: TranslocoService,
    private apollo: Apollo
  ) {}

  static getTimeSheetsById(
    id: string
  ): (state: TimeSheetStateModel) => TimeSheet[] {
    return createSelector([TimeSheetState], (state: TimeSheetStateModel) =>
      state.timeSheets.filter((x) => x.id === id)
    );
  }

  static getTechnicianTimeSheets(
    techNo: string
  ): (state: TimeSheetStateModel) => TimeSheet[] {
    return createSelector([TimeSheetState], (state: TimeSheetStateModel) =>
      state.timeSheets.filter((x) => x.technicianCode === techNo)
    );
  }

  static getAllTimeSheetsByWorkOrderLine(
    no: string,
    line: number
  ): (state: TimeSheetStateModel) => TimeSheet[] {
    return createSelector([TimeSheetState], (state: TimeSheetStateModel) =>
      state.timeSheets.filter(
        (x) =>
          x.source_Work_Order_No === no && x.source_Work_Order_Line === line
      )
    );
  }

  @Selector()
  static getTimeSheets(state: TimeSheetStateModel): TimeSheet[] {
    return state.timeSheets.filter((x) => !x.posted);
  }

  @Selector()
  static getPendingApprovalTimeSheets(state: TimeSheetStateModel): TimeSheet[] {
    return state.timeSheets.filter((x) => x.approval_Status === 'Pending');
  }

  @Selector()
  static getPostedTimeSheets(state: TimeSheetStateModel): TimeSheet[] {
    return state.timeSheets.filter((x) => x.posted);
  }

  @Selector()
  static getAllTimeSheets(state: TimeSheetStateModel): TimeSheet[] {
    return state.timeSheets;
  }

  @Selector()
  static getSelectedTimeSheet(state: TimeSheetStateModel): TimeSheet | null {
    return state.selectedtimeSheet;
  }

  @Action(GetTimeSheets, { cancelUncompleted: true })
  getTimeSheets(
    { getState, setState }: StateContext<TimeSheetStateModel>,
    { filter, bustCache }: GetTimeSheets
  ): Observable<TimeSheet[]> {
    const company = this.store.selectSnapshot(AuthState.company);
    let state = getState();
    if (state.initialized && !bustCache) return of(state.timeSheets);
    return this.timeSheetStoreService.fetchTimeSheets(company, filter).pipe(
      tap((result) => {
        state = getState();
        setState({
          ...state,
          timeSheets: result,
          initialized: true,
        });
      })
    );
  }

  @Action(GetTimeSheet, { cancelUncompleted: true })
  getTimeSheet(
    { getState, setState }: StateContext<TimeSheetStateModel>,
    { woNo, wolNo, lineNo }: GetTimeSheet
  ): Observable<TimeSheet> {
    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeSheetStoreService
      .fetchTimeSheet(company, woNo, wolNo, lineNo)
      .pipe(
        tap((result) => {
          const state = getState();

          const tsList = [...state.timeSheets];
          const i = tsList.findIndex(
            (item) =>
              item.source_Work_Order_No +
                item.source_Work_Order_Line +
                item.time_Sheet_Line_No ===
              woNo + wolNo + lineNo
          );
          if (~i) tsList[i] = result;
          else tsList.push(result);

          setState({
            ...state,
            timeSheets: tsList,
            selectedtimeSheet: result,
          });
        })
      );
  }

  @Action(GetWorkOrderTimeSheets, { cancelUncompleted: true })
  getWorkOrderTimeSheets(
    { getState, setState }: StateContext<TimeSheetStateModel>,
    { workOrderNo }: GetWorkOrderTimeSheets
  ): Observable<TimeSheet[]> {
    return this.timeSheetStoreService
      .fetchWorkOrderTimeSheets(workOrderNo)
      .pipe(
        tap((results) => {
          const state = getState();

          const timeSheetList = [...state.timeSheets];
          results.forEach((ts) => {
            const timeSheetIndex = timeSheetList.findIndex(
              (item) => item.id === ts.id
            );
            if (~timeSheetIndex) {
              timeSheetList[timeSheetIndex] = ts;
            } else {
              timeSheetList.push(ts);
            }
          });

          setState({
            ...state,
            timeSheets: [...timeSheetList],
          });
        })
      );
  }

  @Action(DeleteTimeSheet)
  deleteTimeSheet(
    ctx: StateContext<TimeSheetStateModel>,
    { woNo, wolNo, tsNo, optimisticCacheUpdate }: DeleteTimeSheet
  ): Observable<any> {
    if (optimisticCacheUpdate)
      this.timeSheetStoreService.optimisticDataDelete([
        {
          source_Work_Order_No: woNo,
          source_Work_Order_Line: wolNo,
          time_Sheet_Line_No: tsNo,
        } as unknown as TimeSheet,
      ]);

    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeSheetStoreService
      .deleteTimeSheet(woNo, wolNo, tsNo, company)
      .pipe(
        tap(() => {
          if (!optimisticCacheUpdate) this.timeSheetStoreService.clearCache();

          // No confirmation toast to be added, as it is not relevant in most cases.
        })
      );
  }

  @Action(UpdateCreateTimeSheets)
  updateCreateTimeSheets(
    ctx: StateContext<TimeSheetStateModel>,
    { payload, optimisticCacheUpdate }: UpdateCreateTimeSheets
  ): Observable<TimeSheet[]> {
    if (optimisticCacheUpdate)
      this.timeSheetStoreService.optimisticDataUpdate(payload);

    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeSheetStoreService
      .updateCreateTimeSheets(payload, company)
      .pipe(
        tap((result) => {
          if (!result) throw new Error('Business central result is invalid');
          if (!optimisticCacheUpdate)
            this.timeSheetStoreService.clearCache(true);

          // No confirmation toast to be added, as it is not relevant in most cases.
        })
      );
  }

  @Action(UpdatePostedTimesheetLinesStatus)
  updateFinishedWorkOrderLinesStatus(
    ctx: StateContext<TimeSheetStateModel>,
    { payload, optimisticCacheUpdate }: UpdatePostedTimesheetLinesStatus
  ): Observable<BatchModifyApprovalStatusDto> {
    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeSheetStoreService
      .updateFinishedWorkOrderLinesStatus(payload, company)
      .pipe(
        tap(() => {
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'postedTimesheets',
          });
        })
      );
  }

  @Action(SetSelectedTimeSheet)
  setSelectedTimeSheet(
    { getState, setState }: StateContext<TimeSheetStateModel>,
    { payload }: SetSelectedTimeSheet
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedtimeSheet: payload,
    });
  }
}
